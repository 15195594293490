<template>
  <div class="container">
    <div class="container-content">
      <div v-if="tabbarIndex == 0" class="container-content-home">
        <div class="content-home-bg">
          <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/home_bg.png" />
        </div>
        <div class="content-home-nodata" v-if="list.length == 0">
          <img class="nodata" src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png" alt="">
          <div>您还没有在约会员哦</div>
        </div>
        <div class="content-home-list" v-else>

          <div class="home-list-item">
            <div class="item-card" v-for="(item, index) in list" :key="index">
              <img :src="item.st_bg" />
              <div class="left-icon">
                <img :src="item.st_icon" />
              </div>
              <div class="center-icon">
                <img :src="item.st_hui" />
              </div>
              <div class="center-btn" @click="toMembership(item)">领权益</div>
              <div class="right-top">已开通</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tabbarIndex == 1" class="container-content-mine">
        <div class="mine-head">
          <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_bg.png" />
          <div class="mine-head-info">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_head.png" />
            <div>{{ taiyue_user_info.phone_number }}</div>
          </div>
        </div>
        <div class="mine-content">
          <div class="mine-content-item" @click="settingItem(index)" v-for="item, index in settingList" :key="index">
            <div class="item-left">
              <img :src="item.imgUrl" alt="">
              {{ item.item_name }}
            </div>
            <div class="item-right">
              <div class="item-right-phone" @click="copyToClipboard(item.item_num, '客服热线')" v-if="index == 3">{{
                item.item_num }}</div>
              <img v-else src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/right.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-footer">
      <div class="container-footer-btn" @click="changeTab(index)" :class="tabbarIndex == index ? 'active' : ''"
        v-for="item, index in tabbarList" :key="index">
        <img class="footer-btn-img" :src="tabbarIndex == index ? item.fillimg : item.img" />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatopenid, wechatLogin, getUser } from "@/api/huimo/receive.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isWeixin, setAppName } from "@/utils/common";
export default {
  data() {
    return {
      login_code: '',
      wechat_open_id: '',
      appName: "",
      tabbarIndex: 0,
      tabbarList: [
        {
          name: "会员",
          fillimg: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/tabar_homefill.png',
          img: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/tabar_home.png'
        },
        {
          name: "我的",
          fillimg: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/tabar_minefill.png',
          img: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/tabar_mine.png'
        }
      ],
      settingList: [
        {
          item_name: "开通/续费记录",
          imgUrl: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_sit01.png',
        },
        {
          item_name: "业务退订",
          imgUrl: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_sit02.png',
        },
        {
          item_name: "权益领取记录",
          imgUrl: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_sit03.png',
        },
        {
          item_name: "客服热线",
          imgUrl: 'https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_sit04.png',
          // item_num: '400 009 9713',
          item_num: this.appName == "huanxinxiang-huimo" ? '400 007 8508' : this.appName == "huanxinxiang-heyou" ? '400 660 8109' : '400 007 8508',
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["taiyue_token"]),
    ...mapGetters(["taiyue_user_info"]),
    list() {
      const data = {
        视听甄选会员: {
          page: "/hz_huimo/receive/membership",
          class: "",
          st_bg: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/st_bg.png",
          st_hui: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/st_hui.png",
          st_icon: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/st_icon.png",
        },
        优生活会员: {
          page: "/hz_huimo/receive/membership",
          class: "",
          st_bg: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/youLife_bg.png",
          st_hui: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/youLife_hui.png",
          st_icon: "https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/youLife_icon.png",
        },
      };

      let vipList = this.taiyue_user_info.available_vip;
      return vipList.map((i) => ({
        ...data[i],
        title: i,
      }));
    },

  },
  created() {
    this.appName = setAppName();
    console.log(this.list, this.taiyue_token);
    const { code = "" } = this.$route.query;
    this.login_code = code;
    console.log('this.login_code===', this.login_code, isWeixin())


    if (isWeixin()) {//微信环境自动登录
      console.log('微信环境')
      if (!this.taiyue_token) {
        this.getOpenid();
      } else {
        this["taiyue/getUserInfo"]();
      }
    } else {//其他环境跳转手机验证码登录
      console.log('其他环境===homepage')
      if (!this.taiyue_token) {
        this.$router.push("/hz_huimo/receive/login");
      } else {
        this["taiyue/getUserInfo"]();
      }
    }
    this.tabbarIndex = sessionStorage.getItem('tabStatus') || 0;//我的页面返回确保在我的tabar
  },
  methods: {
    ...mapMutations(["taiyue/SET_TOKEN"]),
    ...mapMutations(["taiyue/SET_USER_INFO"]),
    ...mapMutations(["taiyue/SET_WECHAT_OPEN_ID"]),
    ...mapActions(["taiyue/getUserInfo"]),
    changeTab(index) {
      this.tabbarIndex = index;
      sessionStorage.setItem('tabStatus', index);

    },
    settingItem(index) {
      switch (index) {
        case 0: this.toOrder(); // 开通/续费记录
          break;
        case 1: this.topact(); // 业务退订
          break;
        case 2: this.toequity(); // 权益领取记录
          break;
        case 3: ;
          break;
      }
    },
    // 领取权益
    toMembership(item) {
      console.log(this.list, this.taiyue_user_info, this.taiyue_token);
      this.$router.push({
        path: "/hz_huimo/receive/membership",
        query: {
          vipName: item.title,
        },
      });
    },
    // 开通/续费记录
    toOrder() {
      this.$router.push("/hz_huimo/receive/record");
    },
    // 业务退订  理解为解约
    topact() {
      this.$router.push("/hz_huimo/receive/pact");
    },
    // 权益领取记录
    toequity() {
      this.$router.push("/hz_huimo/receive/equity");
    },
    getOpenid() {
      const urlCode = new URLSearchParams(window.location.search).get("code");
      if (!urlCode && !this.taiyue_token) {
        let redirectUri = encodeURIComponent(`${location.href}`);
        //   this["taiyue/SET_TOKEN"]("");
        //   const appId = "wxccc0ead6c26c9484";
        //   const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        //   window.location.replace(url);
        //   return;
      }

      getWechatopenid({ login_code: this.login_code }).then((res) => {
        if (res.code == 0) {
          this.wechat_open_id = res.wechat_open_id;
          this["taiyue/SET_WECHAT_OPEN_ID"](res.wechat_open_id);
          this.initLogin();
        } else {

        }
      })
    },
    async initLogin() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await wechatLogin({ wechat_open_id: this.wechat_open_id });
      console.log(res);
      this.$toast.clear();
      if (res.code == 0) {
        this["taiyue/SET_TOKEN"](res.token);
        this["taiyue/getUserInfo"]();
      } else {
        this.$toast.fail(res.message);
        this.$router.push({
          path: "/hz_huimo/receive/login",
          query: {
            wechat_open_id: this.wechat_open_id,
          },
        });
      }
    },
    copyToClipboard(text, name) {
      const textarea = document.createElement("textarea");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);
      textarea.value = text;
      textarea.select();

      try {
        document.execCommand("copy");
        this.$toast.success(`${name}已复制到剪贴板`);
      } catch (err) {
        this.$toast.error(`复制${name}失败，请手动复制`);
      } finally {
        document.body.removeChild(textarea);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #F5F5F5;

  .container-content {
    width: 100%;
    height: calc(100% - 110px);

    .container-content-home {
      width: 100%;
      height: 100%;

      .content-home-bg {
        width: 100%;
        height: 430px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content-home-nodata {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 340px);
        position: relative;

        img {
          width: 354px;
          margin-top: 170px;
        }

        div {
          color: #999999;
          font-size: 28px;
          margin-top: 16px;
        }
      }

      .content-home-list {
        width: 100%;
        height: calc(100% - 340px);
        position: relative;

        .home-list-item {
          width: 100%;
          height: 100%;
          position: absolute;
          top: -100px;
          overflow-y: scroll;

          .item-card {
            width: 666px;
            height: 257px;
            margin: 0 auto 10px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .left-icon {
              width: 128px;
              height: 42px;
              position: absolute;
              left: 41px;
              top: 37px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .center-icon {
              width: 190px;
              height: 175px;
              position: absolute;
              right: 48px;
              top: 0px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .center-btn {
              width: 172px;
              height: 60px;
              position: absolute;
              right: 59px;
              bottom: 44px;
              background: linear-gradient(136deg, #803F32 0%, #FF9F67 100%);
              border-radius: 30px;
              font-weight: 500;
              font-size: 26px;
              color: #FFCAB1;
              line-height: 60px;
              text-align: center;
              font-style: normal;
            }

            .right-top {
              position: absolute;
              right: 0;
              top: 0px;
              width: 100px;
              height: 46px;
              background: #FFFFFF;
              border-radius: 0px 23px 0px 23px;
              opacity: 0.35;
              font-weight: 400;
              font-size: 24px;
              color: #A57B4D;
              line-height: 46px;
              text-align: center;
            }
          }
        }
      }

    }

    .container-content-mine {
      width: 100%;
      height: 100%;
      position: relative;

      .mine-head {
        width: 100%;
        height: 390px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .mine-head-info {
          width: 100%;
          height: 105px;
          position: absolute;
          bottom: 100px;
          box-sizing: border-box;
          padding-left: 40px;
          display: flex;
          align-items: center;
          color: #FFFFFF;

          img {
            width: 105px;
            height: 100%;
            margin-right: 20px;
          }
        }
      }

      .mine-content {
        width: 690px;
        // height: 439px;
        background: #FFFFFF;
        border-radius: 24px;
        position: absolute;
        top: 320px;
        left: 50%;
        transform: translateX(-50%);

        .mine-content-item {
          width: 100%;
          height: 100px;
          box-sizing: border-box;
          padding: 0 30px;

          .item-left {
            width: 50%;
            height: 100%;
            float: left;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 32px;
            color: #666666;

            img {
              width: 34px;
              height: 34px;
              margin-right: 20px;
            }
          }

          .item-right {
            height: 100%;
            float: right;
            display: flex;
            align-items: center;

            .item-right-phone {
              width: 240px;
              text-align: right;
              color: #999999;
            }

            img {
              width: 44px;
              height: 44px;
            }
          }
        }
      }
    }
  }

  .container-footer {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 10px;
    border-top: 1px solid #F1F1F1;

    .container-footer-btn {
      width: 50%;
      height: 100%;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: 20px;

      .footer-btn-img {
        width: 46px;
        height: 46px;

      }
    }

    .active {
      color: #FF5000;
    }
  }

}
</style>
